import styled from 'styled-components';
// components
import Icon from 'cds/icons';
// styles
import { palette } from 'cds/styles';

/**
 * 알림 아이콘
 * @param {object} props
 * @param {number=} props.width 가로길이
 * @param {number=} props.height 세로길이
 * @param {boolean=} props.isActive 활성화 여부
 * @param {number=} props.count 읽지 않은 알림 개수
 */
const NotifyIcon = ({
  width = 32,
  height = 32,
  isActive,
  count,
  name = 'ic_alarm_s',
  ...props
}) => {
  const countMessage = count > 99 ? '99+' : count;

  return (
    <i style={{ position: 'relative', display: 'inherit' }}>
      <Icon name={name} width={width} height={height} {...props} />
      {isActive && !count && <BadgeCircle />}
      {!!count && (
        <Badge className="noselect" width={width}>
          {countMessage}
        </Badge>
      )}
    </i>
  );
};

const BadgeCircle = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  top: -2px;
  right: -2px;
  border-radius: 4px;
  background-color: ${palette.red040};
`;

const Badge = styled.div`
  position: absolute;
  min-width: 16px;
  height: 16px;
  top: 0;
  left: ${({ width }) => `calc(${width}px / 2)`};
  padding: 0 4px;
  border: 1px solid white;
  border-radius: 16px;
  background-color: ${palette.red040};
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: white;
`;

export default NotifyIcon;
