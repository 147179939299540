import styled from 'styled-components';
// styles
import { mediaQuery, mediaValue } from '../styles';

/**
 * 메인 레이아웃
 */
const MainLayout = styled.div`
  width: ${mediaValue.medium}px;
  max-width: 100%;
  min-height: calc(100vh - 56px); // - header
  margin: 0 auto;

  ${mediaQuery.large} {
    min-height: calc(100vh - 84px); // - header
  }
`;

export default MainLayout;
