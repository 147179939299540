import { useEffect } from 'react';

/**
 * Infinite Scroll hook
 * @param {object} props
 * @param {NodeElement | null=} props.root 뷰포트 Element
 * @param {NodeElement | null} props.target 탐지 타겟 Element
 * @param {IntersectionObserverCallback} props.onIntersect 감지 콜백 함수
 * @param {number=} props.threshold 교차 범위
 *
 * @example
 * const target = useRef(null);
 * const enableMoreData = useRef(true);
 *
 * useInfiniteScroll({
 *   target,
 *   onIntersect: ([entry]) => {
 *     if (entry.isIntersecting) {
 *       onLoadData();
 *     }
 *   }
 * });
 *
 * const onLoadData = useCallback(async () => {
 *   if (!enableMoreData.current) {
 *     return;
 *   }
 *
 * 	 enableMoreData.current = false;
 *   const data = await api.sampleData();
 *
 *   if (data.payload) {
 *     enableMoreData.current = true;
 *   }
 * }, []);
 *
 * return(
 *   <>
 *     <Item />
 *     <Item />
 *     <Item />
 *     <LastItem ref={target} />
 *   </>
 * );
 */
const useInfiniteScroll = ({ root = null, target, onIntersect, threshold = 0 }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      root,
      threshold,
    });
    const targetDom = target.current;

    if (!targetDom) {
      return;
    }

    observer.observe(targetDom);

    return () => observer && observer.unobserve(targetDom);
  }, [target, root, onIntersect, threshold]);
};

export default useInfiniteScroll;
