import styled from 'styled-components';
// icons
import Icon from 'cds/icons';
// styles
import { palette } from 'cds/styles';

/**
 * 비어있는 컨텐츠일 때, 표시하는 컴포넌트
 * @param {object} props
 * @param {string} props.icon 아이콘 명
 * @param {number} props.w 아이콘 가로 길이
 * @param {number} props.h 아이콘 세로 길이
 * @param {JSX.Element} props.title
 * @param {JSX.Element} props.body
 */
const EmptyContent = ({ icon, w = 52, h = 52, title, body, children }) => {
  return (
    <Wrapper>
      <Icon name={icon} width={w} height={h} fill={palette.grey050} />
      {children && children}
      {!children && (
        <>
          <Title>{title}</Title>
          <Body>{body}</Body>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.strong`
  margin-top: 12px;
  font-weight: 500;
  color: ${palette.font.tertiary1};
`;

const Body = styled.p`
  margin-top: 2px;
  font-size: 14px;
  color: ${palette.grey050};
  letter-spacing: -0.25px;
`;

export default EmptyContent;
