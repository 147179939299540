import styled from 'styled-components';
// styles
import * as animations from 'lib/styles/animations';
import { palette } from 'cds/styles';

/**
 * 로딩
 * @param {object} props
 * @param {number} props.size
 * @param {string} props.color
 */
const SpinLoading = ({ size = 36, color = palette.red040 }) => {
  return (
    <Loader size={size}>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="25 25 50 50">
        <CirclePath cx="50" cy="50" r="20" color={color} />
      </Svg>
    </Loader>
  );
};

const Loader = styled.div`
  position: relative;
  margin: 0px auto;
  width: ${({ size }) => `${size}px`};

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const Svg = styled.svg`
  animation: ${animations.spin} 1.6s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const CirclePath = styled.circle`
  stroke: ${({ color }) => color};
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  fill: none;
  animation: ${animations.dashLoad} 1.2s ease-in-out infinite;
`;

export default SpinLoading;
