import { useState, useRef, useEffect, useCallback } from 'react';
// hooks
import useThrottle from 'hooks/common/event/useThrottle';
// utils
import { getScrollTop } from 'lib/utils/dom';

/**
 * 스크롤에 따른 fixed - DOM을 조작한다.
 * @param {object} param
 * @param {number} start 스크롤 이벤트 시작위치, 0일 경우 해당 참조 요소 높이
 * const [stickyRef, isSticky] = useAccordingToScroll({ start: 0 });
 * <div ref={stickyRef} isSticky={isSticky} />
 */
const useAccordingToScroll = ({ start = 0 }) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  const prevY = useRef(0);
  const prevDirection = useRef(0);
  const currentScroll = useRef(0);
  const direction = useRef(0);

  const throttle = useThrottle();

  const handleScroll = direction => {
    if (direction === 2) {
      setIsSticky(true);
      prevDirection.current = direction;
    } else if (direction === 1) {
      setIsSticky(false);
      prevDirection.current = direction;
    }
  };

  const onScroll = useCallback(() => {
    currentScroll.current = getScrollTop();

    let isFixedVisible = start;

    if (!start && stickyRef.current) {
      isFixedVisible = stickyRef.current.offsetHeight;
    }

    if (currentScroll.current >= isFixedVisible) {
      direction.current = 1;

      if (currentScroll.current > prevY.current) {
        direction.current = 2;
      } else {
        direction.current = 1;
      }
    } else {
      direction.current = 1;
    }

    if (direction.current !== prevDirection.current) {
      handleScroll(direction.current);
    }

    prevY.current = currentScroll.current;
  }, [start]);

  useEffect(() => {
    if (!stickyRef.current) {
      return;
    }

    const throttled = throttle.run(onScroll, 500);

    window.addEventListener('scroll', throttled);
    return () => {
      throttle.cleanUp();
      window.removeEventListener('scroll', throttled);
    };
  }, [onScroll, stickyRef]);

  return [stickyRef, isSticky];
};

export default useAccordingToScroll;
